import React from "react";
import Loading from "../components/loading";

/*
 * When accessing a route like /assets/:collectionId/:serialNumber
 * the webserver won't find a corresponding html document and will
 * refuse to deliver a site and respond with a 404. These routes
 * are called client-side routes. They will only be handled as
 * soon as the JS is loaded into the browser. So the usual fix is
 * to redirect to root and as soon as /index.html is loaded the
 * original route /assets/... will be resolved. In our case the
 * /index.html route should redirect to philatelie.li/website
 * so this does not work. Instead of redirecting to /index.html
 * we'll redirect to /redir/index.html. This will load just fine
 * and as soon as the JS is in the browser the next redirect
 * step to the client-side route can be performed.
 */
export default () => {
  return (
    <div className="container">
      <Loading />
    </div>
  );
};
